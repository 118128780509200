
export default {
  name: 'VInput',
  props: {
    label: {
      type: String,
      default: () => '',
    },

    labelClass: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: () => '',
    },

    type: {
      type: String,
      default: () => 'text',
    },

    autocomplete: {
      type: String,
      default: () => 'off',
    },

    icon: {
      type: [String, Object],
      default: () => '',
    },

    value: {
      type: [String, Array, Number],
      default: () => '',
    },

    width: {
      type: [String, Number],
      default: () => '100%',
    },

    height: {
      type: [String, Number],
      default: () => 45,
    },

    maxlength: {
      type: Number,
      default: () => 255,
    },

    disabled: {
      type: Boolean,
      default: () => false,
    },

    autofocus: {
      type: Boolean,
      default: () => false,
    },

    rules: {
      type: Array,
      default: () => [],
    },

    rows: {
      type: [String, Number],
      default: '',
    },
    cols: {
      type: [String, Number],
      default: '',
    },
    resize: {
      type: Boolean,
      default: true,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: Number,
      default: 0,
    },
    inputClasses: {
      type: String,
      default: () => '',
    },
    format: {
      type: Function,
      default: () => false,
    },

    accept: {
      type: String,
      default: 'string', // number || string
    },
    isPrice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorInput: {
        isError: undefined,
        errorMessage: '',
      },
      border: this.noBorder ? 'border--white' : 'border--default',
      passwordShow: false,
    }
  },
  computed: {
    maxWidth() {
      return {
        maxWidth: typeof this.width === 'number' ? `${this.width}px` : this.width,
      }
    },

    styleInput() {
      const passwordType = this.type === 'password'
      return {
        height: typeof this.height === 'number' ? `${this.height}px` : `${this.height}`,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        paddingLeft:
          this.paddingLeft !== 0
            ? `${this.paddingLeft}px`
            : !passwordType && this.icon && this.iconOption.align === 'left'
            ? `${this.height - 4}px`
            : '12px',
        paddingRight: passwordType || (this.icon && this.iconOption.align === 'right') ? `${this.height - 2}px` : '12px'
      }
    },

    styleTextarea() {
      return {
        resize: !this.resize ? 'none' : 'auto',
        height: this.rows ? 'auto' : typeof this.height === 'number' ? `${this.height}px` : `${this.height}`,
        paddingLeft: this.paddingLeft !== 0 && `${this.paddingLeft}px`,
      }
    },

    iconOption() {
      const iconName = typeof this.icon === 'string' ? this.icon : this.icon.name
      return {
        name: iconName,
        size: this.icon.size || 14,
        align: this.icon.align || 'left',
      }
    },

    valueInput() {
      if (this.accept === 'number') {
        const res = (this.value + '').replace(/\D/g, '')
        const dataFormat = this.format(Number(res))
        return dataFormat === false || dataFormat === '0' ? this.value : dataFormat
      } else return this.value
    },
  },
  methods: {
    onInput(event) {
      if (this.accept === 'number') {
        const res = event.target.value.replace(/\D/g, '')
        const dataFormat = this.format(Number(res))
        this.$emit('input', dataFormat === false || dataFormat === '0' ? res : dataFormat)
      } else {
        this.$emit('input', event.target.value)
      }
    },
    onFocus(event) {
      this.border = 'border--info'
      if (this.errorInput.isError) {
        this.border = 'border--danger'
      }
      this.$emit('focus', event.target.value)
    },
    onBlur(event) {
      this.validateValue(event.target.value)
      this.border = this.noBorder ? 'border--white' : 'border--default'
      if (this.rules.length > 0) {
        if (this.errorInput.isError) {
          this.border = 'border--danger'
        } else {
          this.border = 'border--success'
        }
      }
      const dataEmit = {
        value: event.target.value,
        error: this.errorInput.isError,
        errorMessage: this.errorInput.errorMessage,
      }
      this.$emit('blur', dataEmit)
      this.$emit('isValid', !this.errorInput.isError)
    },

    hidePassword() {
      if (this.type === 'password') {
        this.passwordShow = false
      }
    },

    validateValue(value) {
      value = value.trim()
      this.rules.every((rule) => {
        const next = typeof rule === 'function' ? rule(value) : rule || ''
        if (typeof next === 'string') {
          this.errorInput.isError = true
          this.errorInput.errorMessage = next
          this.borderColor('danger')
          return false
        } else {
          this.errorInput.isError = false
          this.errorInput.errorMessage = ''
          this.borderColor('success')
          return true
        }
      })
    },

    borderColor(value) {
      this.border = 'border--' + value
    },

    checkValidate() {
      this.validateValue(this.value)
      return this.errorInput.isError
    },

    onEnter() {
      this.$emit('enter')
    },
  },
}
