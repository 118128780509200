export const InfiniteScroll = () => import('../../common/components/InfiniteScroll/index.vue' /* webpackChunkName: "components/infinite-scroll" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../common/components/Loading/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../common/components/Logo/index.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const VAvatar = () => import('../../common/components/VAvatar/index.vue' /* webpackChunkName: "components/v-avatar" */).then(c => wrapFunctional(c.default || c))
export const VBreadcrumb = () => import('../../common/components/VBreadcrumb/index.vue' /* webpackChunkName: "components/v-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const VButton = () => import('../../common/components/VButton/index.vue' /* webpackChunkName: "components/v-button" */).then(c => wrapFunctional(c.default || c))
export const VChip = () => import('../../common/components/VChip/index.vue' /* webpackChunkName: "components/v-chip" */).then(c => wrapFunctional(c.default || c))
export const VDatePicker = () => import('../../common/components/VDatePicker/index.vue' /* webpackChunkName: "components/v-date-picker" */).then(c => wrapFunctional(c.default || c))
export const VDialogConfirm = () => import('../../common/components/VDialog/confirm.vue' /* webpackChunkName: "components/v-dialog-confirm" */).then(c => wrapFunctional(c.default || c))
export const VDialog = () => import('../../common/components/VDialog/index.vue' /* webpackChunkName: "components/v-dialog" */).then(c => wrapFunctional(c.default || c))
export const VDropdown = () => import('../../common/components/VDropdown/index.vue' /* webpackChunkName: "components/v-dropdown" */).then(c => wrapFunctional(c.default || c))
export const VDropdownItem = () => import('../../common/components/VDropdown/item.vue' /* webpackChunkName: "components/v-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const VExpand = () => import('../../common/components/VExpand/index.vue' /* webpackChunkName: "components/v-expand" */).then(c => wrapFunctional(c.default || c))
export const VIcon = () => import('../../common/components/VIcon/index.vue' /* webpackChunkName: "components/v-icon" */).then(c => wrapFunctional(c.default || c))
export const VInputCheckbox = () => import('../../common/components/VInput/checkbox.vue' /* webpackChunkName: "components/v-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const VInput = () => import('../../common/components/VInput/index.vue' /* webpackChunkName: "components/v-input" */).then(c => wrapFunctional(c.default || c))
export const VInputRadio = () => import('../../common/components/VInput/radio.vue' /* webpackChunkName: "components/v-input-radio" */).then(c => wrapFunctional(c.default || c))
export const VInputSelect = () => import('../../common/components/VInput/select.vue' /* webpackChunkName: "components/v-input-select" */).then(c => wrapFunctional(c.default || c))
export const VInputSwitch = () => import('../../common/components/VInput/switch.vue' /* webpackChunkName: "components/v-input-switch" */).then(c => wrapFunctional(c.default || c))
export const VLoading = () => import('../../common/components/VLoading/index.vue' /* webpackChunkName: "components/v-loading" */).then(c => wrapFunctional(c.default || c))
export const VOtpInput = () => import('../../common/components/VOtpInput/index.vue' /* webpackChunkName: "components/v-otp-input" */).then(c => wrapFunctional(c.default || c))
export const VPaginate = () => import('../../common/components/VPaginate/index.vue' /* webpackChunkName: "components/v-paginate" */).then(c => wrapFunctional(c.default || c))
export const VTab = () => import('../../common/components/VTab/index.vue' /* webpackChunkName: "components/v-tab" */).then(c => wrapFunctional(c.default || c))
export const VTable = () => import('../../common/components/VTable/index.vue' /* webpackChunkName: "components/v-table" */).then(c => wrapFunctional(c.default || c))
export const VTableItem = () => import('../../common/components/VTable/item.vue' /* webpackChunkName: "components/v-table-item" */).then(c => wrapFunctional(c.default || c))
export const VTooltip = () => import('../../common/components/VTooltip/index.vue' /* webpackChunkName: "components/v-tooltip" */).then(c => wrapFunctional(c.default || c))
export const Authenticated = () => import('../../common/middlewares/authenticated.js' /* webpackChunkName: "components/authenticated" */).then(c => wrapFunctional(c.default || c))
export const NotAuthenticated = () => import('../../common/middlewares/notAuthenticated.js' /* webpackChunkName: "components/not-authenticated" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const CardFeature = () => import('../../components/Card/Feature.vue' /* webpackChunkName: "components/card-feature" */).then(c => wrapFunctional(c.default || c))
export const CardProject = () => import('../../components/Card/Project.vue' /* webpackChunkName: "components/card-project" */).then(c => wrapFunctional(c.default || c))
export const Feature = () => import('../../components/Feature/Feature.vue' /* webpackChunkName: "components/feature" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const IconsAdd = () => import('../../components/Icons/Add.vue' /* webpackChunkName: "components/icons-add" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeft = () => import('../../components/Icons/ArrowLeft.vue' /* webpackChunkName: "components/icons-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeftPopup = () => import('../../components/Icons/ArrowLeftPopup.vue' /* webpackChunkName: "components/icons-arrow-left-popup" */).then(c => wrapFunctional(c.default || c))
export const IconsBell = () => import('../../components/Icons/Bell.vue' /* webpackChunkName: "components/icons-bell" */).then(c => wrapFunctional(c.default || c))
export const IconsBuilding = () => import('../../components/Icons/Building.vue' /* webpackChunkName: "components/icons-building" */).then(c => wrapFunctional(c.default || c))
export const IconsCalendar = () => import('../../components/Icons/Calendar.vue' /* webpackChunkName: "components/icons-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconsCamera = () => import('../../components/Icons/Camera.vue' /* webpackChunkName: "components/icons-camera" */).then(c => wrapFunctional(c.default || c))
export const IconsCard = () => import('../../components/Icons/Card.vue' /* webpackChunkName: "components/icons-card" */).then(c => wrapFunctional(c.default || c))
export const IconsCart = () => import('../../components/Icons/Cart.vue' /* webpackChunkName: "components/icons-cart" */).then(c => wrapFunctional(c.default || c))
export const IconsCartPlus = () => import('../../components/Icons/CartPlus.vue' /* webpackChunkName: "components/icons-cart-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsCategory = () => import('../../components/Icons/Category.vue' /* webpackChunkName: "components/icons-category" */).then(c => wrapFunctional(c.default || c))
export const IconsChooseAddress = () => import('../../components/Icons/ChooseAddress.vue' /* webpackChunkName: "components/icons-choose-address" */).then(c => wrapFunctional(c.default || c))
export const IconsCircleClose = () => import('../../components/Icons/CircleClose.vue' /* webpackChunkName: "components/icons-circle-close" */).then(c => wrapFunctional(c.default || c))
export const IconsCircleLocation = () => import('../../components/Icons/CircleLocation.vue' /* webpackChunkName: "components/icons-circle-location" */).then(c => wrapFunctional(c.default || c))
export const IconsCirclePen = () => import('../../components/Icons/CirclePen.vue' /* webpackChunkName: "components/icons-circle-pen" */).then(c => wrapFunctional(c.default || c))
export const IconsCirclePhone = () => import('../../components/Icons/CirclePhone.vue' /* webpackChunkName: "components/icons-circle-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsCircleWarning = () => import('../../components/Icons/CircleWarning.vue' /* webpackChunkName: "components/icons-circle-warning" */).then(c => wrapFunctional(c.default || c))
export const IconsClock = () => import('../../components/Icons/Clock.vue' /* webpackChunkName: "components/icons-clock" */).then(c => wrapFunctional(c.default || c))
export const IconsClose = () => import('../../components/Icons/Close.vue' /* webpackChunkName: "components/icons-close" */).then(c => wrapFunctional(c.default || c))
export const IconsCustomer = () => import('../../components/Icons/Customer.vue' /* webpackChunkName: "components/icons-customer" */).then(c => wrapFunctional(c.default || c))
export const IconsDarkBell = () => import('../../components/Icons/DarkBell.vue' /* webpackChunkName: "components/icons-dark-bell" */).then(c => wrapFunctional(c.default || c))
export const IconsDropDown = () => import('../../components/Icons/DropDown.vue' /* webpackChunkName: "components/icons-drop-down" */).then(c => wrapFunctional(c.default || c))
export const IconsDropOn = () => import('../../components/Icons/DropOn.vue' /* webpackChunkName: "components/icons-drop-on" */).then(c => wrapFunctional(c.default || c))
export const IconsEmail = () => import('../../components/Icons/Email.vue' /* webpackChunkName: "components/icons-email" */).then(c => wrapFunctional(c.default || c))
export const IconsError = () => import('../../components/Icons/Error.vue' /* webpackChunkName: "components/icons-error" */).then(c => wrapFunctional(c.default || c))
export const IconsEyeVisibility = () => import('../../components/Icons/EyeVisibility.vue' /* webpackChunkName: "components/icons-eye-visibility" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebook = () => import('../../components/Icons/Facebook.vue' /* webpackChunkName: "components/icons-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature1 = () => import('../../components/Icons/Feature1.vue' /* webpackChunkName: "components/icons-feature1" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature2 = () => import('../../components/Icons/Feature2.vue' /* webpackChunkName: "components/icons-feature2" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature3 = () => import('../../components/Icons/Feature3.vue' /* webpackChunkName: "components/icons-feature3" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature4 = () => import('../../components/Icons/Feature4.vue' /* webpackChunkName: "components/icons-feature4" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature5 = () => import('../../components/Icons/Feature5.vue' /* webpackChunkName: "components/icons-feature5" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature6 = () => import('../../components/Icons/Feature6.vue' /* webpackChunkName: "components/icons-feature6" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature7 = () => import('../../components/Icons/Feature7.vue' /* webpackChunkName: "components/icons-feature7" */).then(c => wrapFunctional(c.default || c))
export const IconsFeature8 = () => import('../../components/Icons/Feature8.vue' /* webpackChunkName: "components/icons-feature8" */).then(c => wrapFunctional(c.default || c))
export const IconsFilter = () => import('../../components/Icons/Filter.vue' /* webpackChunkName: "components/icons-filter" */).then(c => wrapFunctional(c.default || c))
export const IconsHome = () => import('../../components/Icons/Home.vue' /* webpackChunkName: "components/icons-home" */).then(c => wrapFunctional(c.default || c))
export const IconsInfor = () => import('../../components/Icons/Infor.vue' /* webpackChunkName: "components/icons-infor" */).then(c => wrapFunctional(c.default || c))
export const IconsList = () => import('../../components/Icons/List.vue' /* webpackChunkName: "components/icons-list" */).then(c => wrapFunctional(c.default || c))
export const IconsListOrder = () => import('../../components/Icons/ListOrder.vue' /* webpackChunkName: "components/icons-list-order" */).then(c => wrapFunctional(c.default || c))
export const IconsListSaleOrder = () => import('../../components/Icons/ListSaleOrder.vue' /* webpackChunkName: "components/icons-list-sale-order" */).then(c => wrapFunctional(c.default || c))
export const IconsLocation = () => import('../../components/Icons/Location.vue' /* webpackChunkName: "components/icons-location" */).then(c => wrapFunctional(c.default || c))
export const IconsLocationDot = () => import('../../components/Icons/LocationDot.vue' /* webpackChunkName: "components/icons-location-dot" */).then(c => wrapFunctional(c.default || c))
export const IconsLogo = () => import('../../components/Icons/Logo.vue' /* webpackChunkName: "components/icons-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsLogo1 = () => import('../../components/Icons/Logo1.vue' /* webpackChunkName: "components/icons-logo1" */).then(c => wrapFunctional(c.default || c))
export const IconsMapLocation = () => import('../../components/Icons/MapLocation.vue' /* webpackChunkName: "components/icons-map-location" */).then(c => wrapFunctional(c.default || c))
export const IconsMoney = () => import('../../components/Icons/Money.vue' /* webpackChunkName: "components/icons-money" */).then(c => wrapFunctional(c.default || c))
export const IconsOrderList = () => import('../../components/Icons/OrderList.vue' /* webpackChunkName: "components/icons-order-list" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/Icons/Phone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsPickLocation = () => import('../../components/Icons/PickLocation.vue' /* webpackChunkName: "components/icons-pick-location" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusCircle = () => import('../../components/Icons/PlusCircle.vue' /* webpackChunkName: "components/icons-plus-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsQr = () => import('../../components/Icons/Qr.vue' /* webpackChunkName: "components/icons-qr" */).then(c => wrapFunctional(c.default || c))
export const IconsSafe = () => import('../../components/Icons/Safe.vue' /* webpackChunkName: "components/icons-safe" */).then(c => wrapFunctional(c.default || c))
export const IconsScan = () => import('../../components/Icons/Scan.vue' /* webpackChunkName: "components/icons-scan" */).then(c => wrapFunctional(c.default || c))
export const IconsScanBarCode = () => import('../../components/Icons/ScanBarCode.vue' /* webpackChunkName: "components/icons-scan-bar-code" */).then(c => wrapFunctional(c.default || c))
export const IconsSearch = () => import('../../components/Icons/Search.vue' /* webpackChunkName: "components/icons-search" */).then(c => wrapFunctional(c.default || c))
export const IconsSetting = () => import('../../components/Icons/Setting.vue' /* webpackChunkName: "components/icons-setting" */).then(c => wrapFunctional(c.default || c))
export const IconsShop = () => import('../../components/Icons/Shop.vue' /* webpackChunkName: "components/icons-shop" */).then(c => wrapFunctional(c.default || c))
export const IconsShopOnline = () => import('../../components/Icons/ShopOnline.vue' /* webpackChunkName: "components/icons-shop-online" */).then(c => wrapFunctional(c.default || c))
export const IconsSpeed = () => import('../../components/Icons/Speed.vue' /* webpackChunkName: "components/icons-speed" */).then(c => wrapFunctional(c.default || c))
export const IconsStaff = () => import('../../components/Icons/Staff.vue' /* webpackChunkName: "components/icons-staff" */).then(c => wrapFunctional(c.default || c))
export const IconsSuccess = () => import('../../components/Icons/Success.vue' /* webpackChunkName: "components/icons-success" */).then(c => wrapFunctional(c.default || c))
export const IconsSwitchCamera = () => import('../../components/Icons/SwitchCamera.vue' /* webpackChunkName: "components/icons-switch-camera" */).then(c => wrapFunctional(c.default || c))
export const IconsThreeDots = () => import('../../components/Icons/ThreeDots.vue' /* webpackChunkName: "components/icons-three-dots" */).then(c => wrapFunctional(c.default || c))
export const IconsTick = () => import('../../components/Icons/Tick.vue' /* webpackChunkName: "components/icons-tick" */).then(c => wrapFunctional(c.default || c))
export const IconsTrash = () => import('../../components/Icons/Trash.vue' /* webpackChunkName: "components/icons-trash" */).then(c => wrapFunctional(c.default || c))
export const IconsWarehouse = () => import('../../components/Icons/Warehouse.vue' /* webpackChunkName: "components/icons-warehouse" */).then(c => wrapFunctional(c.default || c))
export const IconsWarning = () => import('../../components/Icons/Warning.vue' /* webpackChunkName: "components/icons-warning" */).then(c => wrapFunctional(c.default || c))
export const IconsWeb = () => import('../../components/Icons/Web.vue' /* webpackChunkName: "components/icons-web" */).then(c => wrapFunctional(c.default || c))
export const IconsWeight = () => import('../../components/Icons/Weight.vue' /* webpackChunkName: "components/icons-weight" */).then(c => wrapFunctional(c.default || c))
export const IconsX = () => import('../../components/Icons/X.vue' /* webpackChunkName: "components/icons-x" */).then(c => wrapFunctional(c.default || c))
export const IconsModern = () => import('../../components/Icons/modern.vue' /* webpackChunkName: "components/icons-modern" */).then(c => wrapFunctional(c.default || c))
export const IconsQuatity = () => import('../../components/Icons/quatity.vue' /* webpackChunkName: "components/icons-quatity" */).then(c => wrapFunctional(c.default || c))
export const Introduce = () => import('../../components/Introduce/Introduce.vue' /* webpackChunkName: "components/introduce" */).then(c => wrapFunctional(c.default || c))
export const MapLocation = () => import('../../components/MapLocation/index.vue' /* webpackChunkName: "components/map-location" */).then(c => wrapFunctional(c.default || c))
export const PopupMenu = () => import('../../components/Popup/Menu.vue' /* webpackChunkName: "components/popup-menu" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/Popup/index.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const Products = () => import('../../components/Products/Products.vue' /* webpackChunkName: "components/products" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../components/ProjectCard/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
