import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6031579e = () => interopDefault(import('../pages/cabins/index.vue' /* webpackChunkName: "pages/cabins/index" */))
const _1fceb9fc = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _1bab3f81 = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _147bf6d0 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _22a7b28a = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _10decf57 = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _a1dfcd9e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cabins",
    component: _6031579e,
    name: "cabins"
  }, {
    path: "/contact",
    component: _1fceb9fc,
    name: "contact"
  }, {
    path: "/introduce",
    component: _1bab3f81,
    name: "introduce"
  }, {
    path: "/products",
    component: _147bf6d0,
    name: "products"
  }, {
    path: "/project",
    component: _22a7b28a,
    name: "project"
  }, {
    path: "/service",
    component: _10decf57,
    name: "service"
  }, {
    path: "/",
    component: _a1dfcd9e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
