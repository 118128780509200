
export default {
  name: "PopupComponent",

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "33.333%",
    },

    paddingClass: {
      type: String,
      default: "p-4",
    },
  },

  data() {
    return {};
  },

  methods: {
    handleRoute(to) {
      this.$router.push(to);
      this.close();
    },

    close() {
      this.$emit("close");
    },
    onTransitionEnd() {
      // Xử lý sự kiện khi kết thúc transition nếu cần
    },
  },
};
