
export default {
  name: "DefaultLayout",

  methods: {
    makeCall() {
      window.location.href = 'tel:0901987666';
    }
  }
};
