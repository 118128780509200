
export default {
  name: 'IconPhone',
  props: {
    color: {
      type: String,
      default: '#797777',
    },
  },
}
